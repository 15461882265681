import React from "react";
import router from "src/router.tsx";
import store from "./store";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "src/components/ui/Toaster.tsx";
import { ThemeProvider } from "src/components/theme-provider.tsx";

import "./styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <Provider store={store}>
        <RouterProvider router={router}>
          <Toaster />
        </RouterProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
