import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "src/app/config";
import { localStoreConfig } from "src/app/constants";

const initialState = {
  token: "",
  mobileNumber: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
};

export const sendOtp = createAsyncThunk("auth/sendOtp", async (thunkAPI, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await axiosApi().post(`/auth/sendOtp`, {
      mobileNumber: thunkAPI.mobileNumber,
    });

    localStorage.setItem(localStoreConfig.mobileNumber, thunkAPI.mobileNumber);

    return fulfillWithValue(JSON.parse(JSON.stringify(response.data)));
  } catch (error) {
    return rejectWithValue("err");
  }
});

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (thunkAPI) => {
    try {
      const response = await axiosApi().post(`/auth/verifyOtp`, {
        mobileNumber: thunkAPI.mobileNumber,
        otp: thunkAPI.otp,
      });

      const secureToken = response.data?.data?.token;

      if (secureToken) {
        localStorage.setItem(localStoreConfig.secureToken, secureToken);
      }

      return JSON.parse(JSON.stringify(response?.data?.data ?? {}));
    } catch (error) {
      return thunkAPI.rejectWithValue("err");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
    },
    setMobileNumber: (state, payload) => {
      state.mobileNumber = payload?.payload?.mobileNumber;
    },
    setToken: (state, payload) => {
      state.token = payload?.payload?.token;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendOtp.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.error;
      state.isError = true;
    });
    builder.addCase(verifyOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.token = action.payload?.token;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.error;
      state.isError = true;
    });
  },
});

export const { clearState, clearSuccess, setMobileNumber, setToken } =
  authSlice.actions;
export default authSlice.reducer;
