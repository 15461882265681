import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function numberToPriceFormat(number = "") {
  number = number.split(".");
  return (
    number[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") +
    (number[1] ? "." + number[1] : "")
  );
}

export function getAvatarKey(str) {
  if (!str) {
    return "UN";
  }

  var matches = str.match(/\b(\w)/g);
  return matches.join("");
}

export function formatDate(date, opts) {
  return new Intl.DateTimeFormat("en-US", {
    month: opts.month ?? "long",
    day: opts.day ?? "numeric",
    year: opts.year ?? "numeric",
    ...opts,
  }).format(new Date(date));
}

/**
 * Stole this from the @radix-ui/primitive
 * @see https://github.com/radix-ui/primitives/blob/main/packages/core/primitive/src/primitive.tsx
 */
export function composeEventHandlers(
  originalEventHandler,
  ourEventHandler,
  { checkForDefaultPrevented = true } = {}
) {
  return function handleEvent(event) {
    originalEventHandler?.(event);

    if (checkForDefaultPrevented === false || !event.defaultPrevented) {
      return ourEventHandler?.(event);
    }
  };
}
