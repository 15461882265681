import axios from "axios";
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIzYjdiMzFhOC1jYTljLTQ5MzctYjM3MC1hZDgzN2EzZGQyN2IiLCJpYXQiOjE3MjI3Njk3NTN9.wQxY8YWxQDVI4a_fAulaODR-N2bpOgWgIaZ7LjuwiDY";

const DEV_BASE_URL = "http://localhost:5849";
const STG_BASE_URL =
  "https://fm2jklu89l.execute-api.ap-south-1.amazonaws.com/dev";
const PROD_BASE_URL = "";

export default function axiosApi(params) {
  const token = params?.token;
  return axios.create({
    baseURL: STG_BASE_URL,
    headers: {
      "Content-type": params?.contentType ?? "application/json",
      Authorization: token,
    },
  });
}
