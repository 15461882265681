import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "src/app/config";

const initialState = {
  ledgerList: [],
  totalBalance: 0,

  isGetLedgerLoading: false,
  isGetLedgerError: false,
  isGetLedgerSuccess: false,
  getLedgerError: null,
};

export const getLedger = createAsyncThunk(
  "company/getLedger",
  async (thunkAPI, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const { token, formData, companyId, clientId } = thunkAPI ?? {};

      if (!companyId) {
        // log error
        return rejectWithValue("params missing");
      }
      const response = await axiosApi({ token }).post(`/accounts/getLedger`, {
        token,
        companyId,
        clientId,
        year: "2024"
      });
      return fulfillWithValue(JSON.parse(JSON.stringify(response.data)));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ledgerSlice = createSlice({
  name: "ledger",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      //   state.isOnboardCompanyError = false;
      //   state.isOnboardCompanyLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLedger.pending, (state) => {
      state.isGetLedgerLoading = true;
    });
    builder.addCase(getLedger.fulfilled, (state, action) => {
      state.isGetLedgerLoading = false;
      state.isGetLedgerSuccess = true;

      const { totalBalance, ledger } = action.payload?.data ?? {}

      state.totalBalance = totalBalance;
      state.ledgerList = ledger;
    });
    builder.addCase(getLedger.rejected, (state, action) => {
      state.isGetLedgerLoading = false;
      state.getLedgerError = action.payload.error;
      state.isGetLedgerError = true;
    });
  },
});

export const { clearSuccess } = ledgerSlice.actions;
export default ledgerSlice.reducer;
