import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "src/app/config";

const initialState = {
  clientVehicleList: [],

  isClientVehicleLoading: false,
  isClientVehicleError: false,
  isClientVehicleSuccess: false,
  clientVehicleError: null,
};

export const fetchClientVehicle = createAsyncThunk(
  "company/fetchClientVehicle",
  async (thunkAPI, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const { token, companyId, clientId, page, per_page, filters, sorting } =
        thunkAPI ?? {};

      const perPage = parseInt(per_page ?? 0);

      if (!companyId || !clientId) {
        // console error
        return rejectWithValue("params missing");
      }
      const response = await axiosApi({ token }).post(
        `/vehicle/fetchClientVehicle`,
        {
          companyId,
          clientId,
          filters,
          sort: sorting,
          offset: ((page || 1) - 1) * (perPage ?? 0),
          limit: perPage || 10,
        }
      );
      return fulfillWithValue(JSON.parse(JSON.stringify(response.data)));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientVehicleSlice = createSlice({
  name: "clientVehicle",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      //   state.isOnboardCompanyError = false;
      //   state.isOnboardCompanyLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientVehicle.pending, (state) => {
      state.isClientVehicleLoading = true;
    });
    builder.addCase(fetchClientVehicle.fulfilled, (state, action) => {
      state.isClientVehicleLoading = false;
      state.isClientVehicleSuccess = true;
      state.clientVehicleList = action.payload?.data;
    });
    builder.addCase(fetchClientVehicle.rejected, (state, action) => {
      state.isClientVehicleLoading = false;
      state.clientVehicleError = action.payload?.error;
      state.isClientVehicleError = true;
    });
  },
});

export const { clearSuccess } = clientVehicleSlice.actions;
export default clientVehicleSlice.reducer;
