import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import LoadingFallback from "./pages/loading/loading-state";
import GeneralError from "./pages/errors/general-error";
import NotFoundError from "./pages/errors/not-found-error";
import MaintenanceError from "./pages/errors/maintenance-error";
import UnauthorisedError from "./pages/errors/unauthorised-error";
import PrivacyPolicy from "./pages/privacy/privacy";

// import TermOfServices from "./pages/privacy/TermOfServices.tsx";

import TnC from "./pages/privacy/tnc";

const SignIn = React.lazy(() => import("./pages/auth/sign-in"));
const Otp = React.lazy(() => import("./pages/auth/otp"));
const AppShell = React.lazy(() => import("./components/app-shell"));
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const DashboardOverview = React.lazy(
  () => import("./pages/dashboard/dashboard-overview")
);
const Analytics = React.lazy(() => import("./pages/dashboard/analytics"));
const Reports = React.lazy(() => import("./pages/dashboard/reports"));
const Notifications = React.lazy(
  () => import("./pages/dashboard/notification")
);
const Trips = React.lazy(() => import("./pages/trips"));
const Clients = React.lazy(() => import("./pages/clients"));
const ClientsLedger = React.lazy(() => import("./pages/clients/ledger"));
const ClientsReport = React.lazy(() => import("./pages/clients/reports"));
const ClientsVehicle = React.lazy(() => import("./pages/clients/vehicles"));
const ClientsAnalytics = React.lazy(() => import("./pages/clients/analytics"));
const Vehicles = React.lazy(() => import("./pages/vehicles"));
const Settings = React.lazy(() => import("./pages/settings"));
const Expense = React.lazy(() => import("./pages/expense"));

const router = createBrowserRouter([
  // Auth routes
  {
    path: "/sign-in",
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <SignIn />
      </Suspense>
    ),
  },
  {
    path: "/otp",
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <Otp />
      </Suspense>
    ),
  },

  // Main routes
  {
    path: "/",
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <AppShell />
      </Suspense>
    ),
    errorElement: <GeneralError />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <Dashboard />
          </Suspense>
        ),
        errorElement: <GeneralError />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <DashboardOverview />
              </Suspense>
            ),
          },
          {
            path: "overview",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <DashboardOverview />
              </Suspense>
            ),
          },
          {
            path: "analytics",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <Analytics />
              </Suspense>
            ),
          },
          {
            path: "reports",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <Reports />
              </Suspense>
            ),
          },
          {
            path: "notifications",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <Notifications />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "trips",
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <Trips />
          </Suspense>
        ),
      },
      {
        path: "clients",
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <Clients />
          </Suspense>
        ),
        errorElement: <GeneralError />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ClientsLedger />
              </Suspense>
            ),
          },
          {
            path: "vehicle",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ClientsVehicle />
              </Suspense>
            ),
          },
          {
            path: "ledger",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ClientsLedger />
              </Suspense>
            ),
          },
          {
            path: "analytics",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ClientsAnalytics />
              </Suspense>
            ),
          },
          {
            path: "report",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ClientsReport />
              </Suspense>
            ),
          },
        ],
      },
      // {
      //   path: "vehicles",
      //   element: (
      //     <Suspense fallback={<LoadingFallback />}>
      //       <Vehicles />
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: "expense",
      //   element: (
      //     <Suspense fallback={<LoadingFallback />}>
      //       <Expense />
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: "settings",
      //   element: (
      //     <Suspense fallback={<LoadingFallback />}>
      //       <Settings />
      //     </Suspense>
      //   ),
      // },
    ],
  },

  // Error routes
  { path: "/500", element: <GeneralError /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/tnc", element: <TnC /> },
  { path: "/404", element: <NotFoundError /> },
  { path: "/503", element: <MaintenanceError /> },
  { path: "/401", element: <UnauthorisedError /> },

  // Fallback 404 route
  { path: "*", element: <NotFoundError /> },
]);

export default router;
