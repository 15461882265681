import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axiosApi from "src/app/config";

const initialState = {
  company: {},
  selectedClient: {},

  isAddClientLoading: false,
  isAddClientError: false,
  isAddClientSuccess: false,
  addClientError: null,

  companyList: [],
  isCompanyListLoading: false,
  isCompanyListError: false,
  isCompanyListSuccess: false,
  companyListError: null,

  clientList: [],
  isClientListLoading: false,
  isClientListError: false,
  isClientListSuccess: false,
  clientListError: null,
};

export const addClient = createAsyncThunk(
  "company/addClient",
  async (
    thunkAPI,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { token, formData } = thunkAPI ?? {};

      if (!formData?.companyId || !formData?.clientName) {
        // log error
        return rejectWithValue("params missing");
      }

      const response = await axiosApi({ token }).post(
        `/company/onboardClient`,
        {
          ...formData,
        }
      );
      return fulfillWithValue(JSON.parse(JSON.stringify(response.data)));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompanyListByPhoneNumber = createAsyncThunk(
  "company/getCompanyListByPhoneNumber",
  async (
    thunkAPI,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { token, phoneNumber } = thunkAPI ?? {};

      if (!phoneNumber) {
        // log error
        return rejectWithValue("params missing");
      }

      const response = await axiosApi({ token }).post(
        `/company/getCompanyListByPhoneNumber`,
        {
          phoneNumber,
        }
      );
      return fulfillWithValue(JSON.parse(JSON.stringify(response.data)));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getClientsList = createAsyncThunk(
  "company/getClientsList",
  async (
    thunkAPI,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { token, companyId } = thunkAPI ?? {};

      if (!companyId) {
        // log error
        return rejectWithValue("params missing");
      }

      const response = await axiosApi({ token }).post(
        `/company/getClientsList`,
        {
          companyId,
        }
      );
      return fulfillWithValue(JSON.parse(JSON.stringify(response.data)));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      //   state.isOnboardCompanyError = false;
      //   state.isOnboardCompanyLoading = false;
    },

    setSelectedClient: (state, payload) => {
      state.selectedClient = payload?.payload;

      // trigger all changes from here
    },

    updateSelectedClient: (state, payload) => {
      const { clientList } = current(state)
      const selectedCompanyName = payload.payload
      const selectedUpdatedClient = clientList.find(
        (client) =>
          client?.companyName === selectedCompanyName
      );

      if (selectedUpdatedClient) {
        state.selectedClient = selectedUpdatedClient;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addClient.pending, (state) => {
      state.isAddClientLoading = true;
    });
    builder.addCase(addClient.fulfilled, (state, action) => {
      state.isAddClientLoading = false;
      state.isAddClientSuccess = true;
    });
    builder.addCase(addClient.rejected, (state, action) => {
      state.isAddClientLoading = false;
      state.addClientError = action.payload.error;
      state.isAddClientError = true;
    });

    builder.addCase(getCompanyListByPhoneNumber.pending, (state) => {
      state.isCompanyListLoading = true;
    });
    builder.addCase(getCompanyListByPhoneNumber.fulfilled, (state, action) => {
      state.isCompanyListLoading = false;
      state.isCompanyListSuccess = true;
      state.companyList = action.payload?.data;
    });
    builder.addCase(getCompanyListByPhoneNumber.rejected, (state, action) => {
      state.isCompanyListLoading = false;
      state.companyListError = action.payload.error;
      state.isCompanyListError = true;
    });

    builder.addCase(getClientsList.pending, (state) => {
      state.isClientListLoading = true;
    });
    builder.addCase(getClientsList.fulfilled, (state, action) => {
      state.isClientListLoading = false;
      state.isClientListSuccess = true;
      state.clientList = action.payload?.data;
    });
    builder.addCase(getClientsList.rejected, (state, action) => {
      state.isClientListLoading = false;
      state.clientListError = action.payload.error;
      state.isClientListError = true;
    });
  },
});

export const { clearSuccess, setSelectedClient, updateSelectedClient } = clientSlice.actions;
export default clientSlice.reducer;
