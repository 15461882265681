import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axiosApi from "src/app/config";
import { companyTypes, userRoles } from "src/app/constants";

const initialState = {
  userDetails: {},
  selectedCompanyUser: {},
  companyUserDetails: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,

  isAddUserToCompanyLoading: false,
  isAddUserToCompanyError: false,
  isAddUserToCompanySuccess: false,
  addUserToCompanyError: null,
};

export const getCompanyUserDetails = createAsyncThunk(
  "companyUser/fetchCompanyUserList",
  async (thunkAPI, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosApi({ token: thunkAPI.token }).post(
        `/companyUser/fetchCompanyUserList`,
        {
          companyType: companyTypes.CRUSHER,
          userRoleList: [
            userRoles.COMPANY_ADMIN,
            userRoles.COMPANY_EDITOR,
            userRoles.DEFAULT_USER,
          ],
        }
      );

      const responseData = JSON.parse(JSON.stringify(response?.data?.data));
      const { userDetails, companyUserDetails } = responseData ?? {};
      if (!userDetails?.selectedCompanyUserId && companyUserDetails?.[0]?.id) {
        dispatch(
          updateUserDetails({
            token: thunkAPI.token,
            selectedCompanyUserId: companyUserDetails?.[0]?.id,
          })
        );
      }

      return fulfillWithValue(responseData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addUserToCompany = createAsyncThunk(
  "user/addUserToCompany",
  async (thunkAPI, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const { token, formData, companyId } = thunkAPI ?? {};
      const response = await axiosApi({ token }).post(
        `/user/addUserToCompany`,
        {
          companyId,
          ...formData,
        }
      );
      return fulfillWithValue(JSON.parse(JSON.stringify(response.data)));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "user/updateUserDetails",
  async (thunkAPI, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const { token, selectedCompanyUserId } = thunkAPI ?? {};
      const response = await axiosApi({ token }).post(
        `/user/updateUserDetails`,
        {
          selectedCompanyUserId,
        }
      );
      return fulfillWithValue(JSON.parse(JSON.stringify(response.data)));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
    },
    updateSelectedUser: (state, payload) => {
      const { companyUserDetails } = current(state)
      const selectedCompanyName = payload.payload
      const selectedCompanyUser = companyUserDetails.find(
        (companyUser) =>
          companyUser?.companyData?.companyName === selectedCompanyName
      );

      state.selectedCompanyUser = selectedCompanyUser;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;

      const { userDetails, companyUserDetails } = action.payload ?? {};
      let selectedCompanyUser;
      if (userDetails?.selectedCompanyUserId) {
        selectedCompanyUser = companyUserDetails.find(
          (companyUser) =>
            companyUser?.id === userDetails?.selectedCompanyUserId
        );
      } else {
        selectedCompanyUser = companyUserDetails[0];
      }

      state.userDetails = userDetails;
      state.companyUserDetails = companyUserDetails;
      state.selectedCompanyUser = selectedCompanyUser;
    });
    builder.addCase(getCompanyUserDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
      state.isError = true;
    });

    builder.addCase(addUserToCompany.pending, (state) => {
      state.isAddUserToCompanyLoading = true;
    });
    builder.addCase(addUserToCompany.fulfilled, (state, action) => {
      state.isAddUserToCompanyLoading = false;
      state.isAddUserToCompanySuccess = true;
    });
    builder.addCase(addUserToCompany.rejected, (state, action) => {
      state.isAddUserToCompanyLoading = false;
      state.addUserToCompanyError = action.payload.error;
      state.isAddUserToCompanyError = true;
    });
  },
});

export const { clearSuccess, updateSelectedUser } = userSlice.actions;
export default userSlice.reducer;
